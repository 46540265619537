<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-game-course"> 
    <!-- <scaleWrap> -->
      <div class="home">
          <div class="word-container">
            <div @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index)" :id="`word${index}`" class="word" v-for="(word, index) in showWordList.slice(0, 3)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
          <div class="word-container">
            <div @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+3)" :id="`word${index+3}`" class="word" v-for="(word, index) in showWordList.slice(3, 6)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+3)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
          <img id="door" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/door.png"/>
        
        <!-- <div class="word-container">
          <div @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+6)" :id="`word${index+6}`" class="word" v-for="(word, index) in showWordList.slice(6, 9)" :key="word.name">
            <img src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/0.png"/>
            <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+6)" :style="{backgroundImage: 'url(' + word.icon + ')'}"></div>
          </div>
        </div> -->
        <img id="person" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/walk/0.png">
        <!-- <img id="partner" src="@/assets/game1-2/clap/0.png"> -->
        <img class="logo" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/logo.png"/>
        <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
           <img @click="backHandler" class="back" src="@/assets/back.png"/>
           <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
           <img @click="startHandler" class="voice" src="@/assets/voice.png"/>
        </div>
        <!-- <div class="sound-box-bottom"> -->
           <!-- <img @click="backHandler" class="back" src="@/assets/back.png"/> -->
           <!-- <img @click="reverseHandler" class="reverse" src="@/assets/reverse.png"/> -->
           <!-- <img @click.stop.prevent="startHandler" class="voice" src="@/assets/voice.png"/> -->
        <!-- </div> -->
        <audio id="myAudio" preload="auto"></audio>
        <audio id="failAudio" preload="auto">
          <source :src="this.failAudio">
        </audio>
        <audio id="ballAudio" preload="auto">
          <source :src="this.ballAudio">
        </audio>
        <audio id="hecaiAudio" preload="auto">
          <source :src="this.hecaiAudio">
        </audio>
        <audio id="tiAudio" preload="auto">
          <source :src="this.tiAudio">
        </audio>
        <audio id="jinAudio" preload="auto">
          <source :src="this.jinAudio">
        </audio>
        <Overlay class="mask-dialog" :show="showStartGameIcon">
          <img class="overlay-bg" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/bg.png"/>
          <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
            <img @click="backHandler" class="back" src="@/assets/back.png"/>
            <!-- <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
            <img @click="startHandler" class="voice" src="@/assets/voice.png"/> -->
          </div>
          <div class="mask-title">{{index}} {{gameName}}</div>
          <img @click="startGameHandler(true)" class="start" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-start.png"/>
        </Overlay>
      </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
// import scaleWrap from '@/components/scale-wrap'
import {shuffle, isEmpty, cloneDeep} from 'lodash'
import { Overlay } from 'vant'
import mixin from '../mixin'
import {Howl} from 'howler'
import {isIOS} from '@/utils/env'
export default {
  name: 'GameCourse',
  mixins: [mixin],
  components: {
    // , scaleWrap
    horizontalScreen, Overlay
  },
  created () {
    this.tiAudioVoice = new Howl({
      src: [this.tiAudio],
      html5: isIOS
    });
    this.jinAudioVoice = new Howl({
      src: [this.jinAudio],
      html5: isIOS
    });
    this.hecaiAudioVoice = new Howl({
      src: [this.hecaiAudio],
      html5: isIOS
    });
    this.ballAudioVoice = new Howl({
      src: [this.ballAudio],
      html5: isIOS
    });
  },
  beforeDestroy () {
    this.tiAudioVoice.pause()
    this.jinAudioVoice.pause()
    this.hecaiAudioVoice.pause()
    this.ballAudioVoice.pause()
  },
  data() {
    return {
      tiAudioVoice: null,
      jinAudioVoice: null,
      hecaiAudioVoice: null,
      ballAudioVoice: null,
      personTarget: {
        left: null,
        top: null,
      },
      ballLocation: [],
      index: this.$route.query.index,
      id: this.$route.query.id,
      gameName: null,
      tiAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/sounds/ti.mp3`,
      jinAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/sounds/jin.mp3`,
      hecaiAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/sounds/suc.mp3`,
      ballAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/sounds/ball.mp3`,
      clickAudio: require(`@/assets/sounds/click.mp3`),
      failAudio: require(`@/assets/sounds/fail.mp3`),
      boardImgLength: 23, // 球
      workImgLength: 19, // 小人走路动画
      digImgLength: 20, // 小人挖宝动画
      sighImgLength: 15 // 小人叹气挖宝动画
    }
  },
  watch: {
    showStartGameIcon(val) {
      if(!val && !this.ballLocation.length){
        this.initLocation()
      }
    },
    isWalk(val) {
      this.$nextTick(() => {
        const person = document.getElementById('person')
        if (val) {
          person.style.zIndex = 60
        } else {
          console.log('person.style.left', person.style.left, this.personTarget.left)
          if (!person.style.left || person.style.left.slice(0,-2) == this.personTarget.left) {
            person.style.zIndex = 40
          }
        }
      })
    }
  },
  mounted() {
    this.initPersonLocation()
  },
  methods: {
    isEmpty,
    initPersonLocation(){
      const personTarget = document.getElementById('person')
      this.personTarget.top = personTarget.offsetTop
      this.personTarget.left = personTarget.offsetLeft
    },
    initLocation() {
      this.$nextTick(() => {
        // const elements = Array.from(document.querySelectorAll('.flower'))
        const elements = [
          document.querySelector('#word0 .flower'),
          document.querySelector('#word1 .flower'),
          document.querySelector('#word2 .flower'),
          document.querySelector('#word3 .flower'),
          document.querySelector('#word4 .flower'),
          document.querySelector('#word5 .flower'),
        ]
        // 横屏
        if (this.isVertical) {
          for(let i = 0; i< elements.length; i++) {
            const ballTarget = elements[i]
            ballTarget.style.position='fixed'
            console.log(ballTarget.offsetTop, document.body.clientHeight - (ballTarget.offsetLeft + ballTarget.offsetHeight))
            this.$set(this.ballLocation, i, {
              top: ballTarget.offsetTop + 'px',
              right: document.body.clientHeight - (ballTarget.offsetLeft + ballTarget.offsetHeight) + 'px'    
            })
            ballTarget.style.top= this.ballLocation[i].top
            ballTarget.style.right=this.ballLocation[i].right
          }
        }
        // 竖屏
        if (!this.isVertical) {
          for(let i = 0; i< elements.length; i++) {
            const ballTarget = elements[i]
            ballTarget.style.position='fixed'
            console.log(ballTarget.offsetTop, document.body.clientWidth - (ballTarget.offsetLeft + ballTarget.offsetWidth))
            this.$set(this.ballLocation, i, {
              top: ballTarget.offsetTop + 'px',
              right: document.body.clientWidth - (ballTarget.offsetLeft + ballTarget.offsetWidth) + 'px'    
            })
            ballTarget.style.top= this.ballLocation[i].top
            ballTarget.style.right=this.ballLocation[i].right
          }
        }
        console.log('ballLocation', this.ballLocation)
      })
    },
    // 点错动画
    // partnerSighGifHandler () {
      // clearInterval(this.timerSigh)
      // let i = 0
      // const partner = document.getElementById('partner')
      // this.timerSigh = setInterval(() => {
      //   this.isWalk = true
      //   if (i >= this.sighImgLength - 1) {
      //     clearInterval(this.timerSigh)
      //     this.isWalk = false
      //     partner.src =require(`@/assets/game1-2/sigh/${0}.png`
      //   } else {
      //     i=i+1
      //     partner.src =require(`@/assets/game1-2/sigh/${i}.png`
      //   }
      // }, 60)
    // },
    // 走路动画
    personWalkGifHandler () {
      clearInterval(this.timerWalk)
      this.timerWalk = null
      let i = 0
      const person = document.getElementById('person')
      this.timerWalk = setInterval(() => {
        this.isWalk = true
        if (i>=this.workImgLength) {
          clearInterval(this.timerWalk)
          this.timerWalk = null
          // this.isWalk = false
        } else {
          i=i+1
          person.src =`https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/walk/${i}.png`
        }
      }, 70)
    },
    // 挖宝动画
    personDigGifHandler (id, index) {
      clearInterval(this.timerDig)
      this.timerDig = null
      let i = this.workImgLength
      const person = document.getElementById('person')
      this.timerDig = setInterval(() => {
        
        this.isWalk = true
        if (i >= this.digImgLength -1) {
          clearInterval(this.timerDig)
          this.timerDig = null
          // this.isWalk = false
          // person.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/walk/${0}.png`
          this.boardGifHandler(`${id}`, index)
        } else {
          i=i+1
          person.src =`https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/walk/${i}.png`
        }
      }, 40)
    },
    // 面板动画
    boardGifHandler (id, index) {
      clearInterval(this.timerBoard)
      this.timerBoard = null
      const person = document.getElementById('person')
      const animeType = this.animeTypes
      let i = 0
      const wordTarget = document.querySelector(`#${id} div`)
      wordTarget.style.display = 'none'
      

      const target = document.querySelector(`#${id} .flower`)
      // target.style.position='fixed'
      // console.log(target.offsetLeft, target.offsetTop, document.body.clientHeight - (target.offsetLeft + target.offsetHeight))
      // target.style.top=target.offsetTop + 'px'
      // target.style.right=document.body.clientHeight - (target.offsetLeft + target.offsetHeight) + 'px'
      const fontSize = document.documentElement.style.fontSize.slice(0, -2)/10
      const door = document.querySelector(`#door`)
      door.offsetHeight
      console.log('door.offsetHeight/2 + target.offsetHeight/2', door.offsetTop + door.offsetHeight/2-target.offsetHeight/2)
      // console.log('fontSize', fontSize.slice(0, -2))
      // document.getElementById('jinAudio').play()
      this.jinAudioVoice.play()

      this.timerBoard = setInterval(() => {
        this.isWalk = true
        if (i>=this.boardImgLength-1) {
          clearInterval(this.timerBoard)
          this.timerBoard = null
          // document.getElementById('hecaiAudio').play()
          this.hecaiAudioVoice.play()
          // 隐藏花
          // target.style.display = 'none'
          // word.style.visibility = 'hidden'

          // this.isWalk = false
          this.showWordList[index].clicked = false
          // 下一个字包括音效 
          this.delayTimer = setTimeout(() => {
            person.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/walk/${0}.png`
            person.style.right = null
            person.style.bottom = null
            person.style.left = `${this.personTarget.left}px`
            person.style.top = `${this.personTarget.top}px`
            this.getData(true)
          }, 4000)
        } else {
          i=i+1
          target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type${animeType}/${i}.png`
          // target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type${animeType}/${i}.png`
        }
      }, 50)
      anime({
        targets: target,
        top: `${door.offsetTop + door.offsetHeight/2-target.offsetHeight/2}px`,
        // bottom: this.isVertical ? `${target.getBoundingClientRect().x-15}px` : null,
        right: `${9*fontSize}px`,
        easing: 'easeOutElastic(1, .6)',
        duration: this.boardImgLength*50,
        complete: () => {
          setTimeout(() => {
            target.style.display = 'none'
            target.style.position = 'fixed'
            target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type${animeType}/${0}.png`
            target.style.top=this.ballLocation[index].top
            target.style.right=this.ballLocation[index].right
          }, 500)
        }
      });
      
    },
    // 打乱文字排序
    async reverseHandler() {
      if (!this.isWalk){
        clearInterval(this.timerWalk)
        this.timerWalk = null
        clearInterval(this.timerSigh)
        this.timerSigh = null
        clearInterval(this.timerClap)
        this.timerClap = null
        clearInterval(this.timerDig)
        this.timerDig = null
        clearInterval(this.timerBoard)
        this.timerBoard = null
        const person = document.getElementById('person')
        person.style.right = null
        person.style.bottom = null
        person.style.left = `${this.personTarget.left}px`
        person.style.top = `${this.personTarget.top}px`
        console.log('ball', this.ballLocation)
        this.audioList = []
        await this.initData()
        this.showWordList = shuffle(this.InitWordList)
        this.wordList = cloneDeep(this.showWordList)
        this.$set(this, 'showWordList', shuffle(this.InitWordList)) 
        this.$set(this, 'wordList', cloneDeep(this.showWordList)) 
        for(let i =0;i<this.InitWordList.length;i++) {
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          // flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/${0}.png`
        }
        for(let i =0;i<this.showWordList.length;i++) {
          this.showWordList[i].clicked = false
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          // flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/${0}.png`
        }
        this.$nextTick(() => {
          // setTimeout(() => {
            for(let i =0;i<this.ballLocation.length;i++) {
              const flowerTarget = document.querySelector(`#word${i} .flower`)
              console.log(11111, this.ballLocation[i], i)
              flowerTarget.style.display = 'block'
              flowerTarget.style.position = 'fixed'
              flowerTarget.style.top = this.ballLocation[i].top
              flowerTarget.style.right = this.ballLocation[i].right
            }
          // }, 500)
        })

        
        // 一切重新开始
        this.getData()
        this.startGameHandler()
      }
    },
    // 字点击
    wordClickHandler(event, word, index) {
      console.log('wordClickHandler', word, event.target.className)
      console.log('word', word, event.target.className)
      console.log('isWalk', this.isWalk)

      if (this.isWalk) return

    
      
      if (this.currentWord.name !== word.name) {
       
        this.playClickAudio()
        this.playFailAudio()
      
        return false
      }
    
      this.showWordList[index].clicked = true

      this.playClickAudio()

      const target = document.getElementById(`word${index}`)
      const person = document.getElementById('person')
      console.log('ggg', target, target.offsetHeight)
   
      this.personWalkGifHandler()
      // 文字消失
      const wordTarget = document.querySelector(`#word${index} div`)
      wordTarget.style.display = 'none'
      // 变换成第一个花苞
      const flowerTarget = document.querySelector(`#word${index} .flower`)
      flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/anime/type0/${1}.png`

      console.log('isVertical', this.isVertical)
      this.animation = anime({
        targets: person,
        left: `${target.offsetLeft-person.offsetWidth*0.7}px`,
        top: `${target.offsetTop+target.offsetHeight - person.offsetHeight}px`,
        // left:  !this.isVertical ? `${target.getBoundingClientRect().x-15}px` : null,
        // top: !this.isVertical ? `${target.getBoundingClientRect().y-100}px` : `${Math.abs(target.getBoundingClientRect().x-100)}px`,
        // // bottom: this.isVertical ? `${target.getBoundingClientRect().x-15}px` : null,
        // left: this.isVertical ? `${target.getBoundingClientRect().y-100}px` : `${target.getBoundingClientRect().x-100}px`,
        easing: 'easeInOutQuad',
        duration: this.workImgLength*70, // 走路多久到达的时间 走路的动作乘以时间
        complete: () => {
          // document.getElementById('tiAudio').play()
          this.tiAudioVoice.play()
          // target.innerHTML = '到达'
          clearInterval(this.timerWalk)
          // this.audioList = [this.hecaiAudio]
          // this.playAudio()
          this.personDigGifHandler(`word${index}`, index)
          
          
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.v-game-course{
  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}
.mask-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  // background: url(~https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  .overlay-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
   .mask-title {
    border: solid 3px #5f0001;
    color: #5f0001;
    font-size: 50px;
    padding: 20px 0;
    text-align: center;
    background: #fff;
    border-radius: 60px;
    width: 76%;
    margin: 5% auto;
  }
  .start {
    display: block;
    width: 124px;
  }
  .btn-restart {
    width: 223.33px;
  }
}

.home {
  height: 100%;
  width: 100%;
  background: url(https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game1-2/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
// .logo {
//   width: 12px;
//   left: 5px;
//   top: 10PX;
//   position: fixed;
// }
.sound-box-top {
  width: 46px;
  right: 20px;
  top: 10PX;
  z-index: 990;
  position: fixed;
  > img {
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.sound-box-bottom {
  z-index: 990;
  width: 46px;
  right: 5px;
  bottom: 5px;
  position: fixed;
  > img {
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.word-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 110px;
  &:nth-of-type(1) {
    // margin-left: 120px;
    margin-top: 100px;
    margin-left: 24%;
  }
  &:nth-of-type(2) {
    margin-left: 11%;
    margin-top: 10px;
  }
  .word {
    width: 110px;
    height: 110px;
    font-size: 30px;
    // font-weight: bolder;
    position: relative;
    .flower {
      width: 110px;
      position: fixed;
    }
    & + .word {
      margin-left: 15px;
    }
    .icon {
      z-index: 60;
      display: block;
      width: 92%;
      height: 90%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 51%;
      transform: translateX(-50%);
      top: 5%;
    }
    .icon-img {
      z-index: 60;
      display: block;
      width: 64px;
      height: 64px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
    }
  }
}
#person {
  width: 215px;
  height: 230.29px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
}
#door {
  width: 254px;
  height: 241.4px;
  position: fixed;
  right: 0;
  bottom: 0;
}
}
</style>